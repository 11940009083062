.artwork-info {
    /* background-color: white; */
    text-align: left;
    padding-left: 10%;
    padding-top: 10px;
    overflow: hidden;
    max-height: 100px;
    transition: max-height 1s ease-in-out, padding 0.75s ease-in-out
}
.artwork-info--collapsed {
    max-height: 0;
    padding: 0;
    transition: max-height 0.5s ease-in-out, padding 0.75s ease-in-out;
}

.artwork-info > p {
    display: table;
    background-color: white;
}

.artwork-info > button {
    color: rgb(35, 145, 77);
    padding: 0;
}