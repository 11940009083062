.network-m {
    max-width: 100vw;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: flex-end;
    overflow-x: auto;
    position: absolute;
    bottom: 10vh;
    width: 100vw;
    height: 60vh;
    scroll-behavior: smooth;
    /* z-index: 4; */
}
.network-m > p {
    /* writing-mode: vertical-lr;
	text-orientation: sideways; */
    color: white;
    margin: 0.5em;
    font-size: 3em;
}
