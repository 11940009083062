.comment-cloud-m {
    /* height: 80vh; */
    width: 100vw;
    max-width: 100vw;
    /* background-color: rgb(200,200,200); */
}
.comment-cloud-m__add-comment {
    width: 90%;
    max-width: 90%;
    z-index: 5;
    position: fixed;
    margin: 1em 0 0 1em;
}
.comment-cloud-m__add-comment > textarea {
    appearance: none;
    outline: none;
    font-family: 'UxumR';
    border-radius: 3px;
    border-width: 1px;
    border-color: rgb(118, 118, 118);
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-height: 2em;
    height: 8vh;
    max-height: 25vh;
    padding: 5px;
    margin-bottom: 0.5em;
}
.comment-cloud-m__add-comment--submit {
    margin-top: -1%;
    appearance: none;
    outline: none;
    height: 2em;
    border-color: rgb(118, 118, 118);
    border-width: 1px;
    border-radius: 3px;
    background-color: rgb(255,255,255);
    font-family: 'UxumR';
    border-width: 1px;
    border-color: rgb(118, 118, 118);
    color: rgb(0,0,0);
    width: 100%;
    padding: 5px;
    cursor: pointer;
    transition: 0.5s ease-in-out;
}
.comment-cloud-m__add-comment--submit:hover {
    background-color: black;
    color: white;
    transition: 0.2s ease-in-out;
    cursor: pointer;
}