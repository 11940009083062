.about-email-m {
    width: 90%;
    max-width: 90%;
    padding: 5%;
    font-size: 1em;
}
.about-email-m > p {
    margin: 1em 0 1em 0;
}
.about-email-m > input {
    font-family: 'UxumR';
    box-sizing: border-box;
    height: 1.75em;
    width: 100%;
    max-width: 100%;
    padding: 5px;
    margin-bottom: 1em;
}
.about-email-m > textarea {
    font-family: 'UxumR';
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 15vh;
    padding: 5px;
    margin-bottom: 1em;
}
.about-email-m__send {
    display: block;
    margin-top: -1%;
    height: 3em;
    padding: 5px;
    border-radius: 3px;
    background-color: white;
    appearance: none;
    font-family: 'UxumR';
    border-width: 1px;
    border-color: rgb(118, 118, 118);
    color: rgb(0,0,0)
}
.about-email-m--loading {
    padding: 5%;
    font-size: 1em;
}
.about-email-m--sent {
    padding: 5%;
}
.about-email-m--sent > p {
    padding: 1em 0 0 0.5em;
    font-size: 1em;
    margin-bottom: 0.5em;
}
.about-email-m--sent > a {
    font-size: 1em;
}