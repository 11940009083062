body {
  margin: 0;
  font-family: 'UxumR', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'UxumR';
    src: local('UxumR'), url('./fonts/UxumGrotesque-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'UxumUL';
    src: url('./fonts/UxumGrotesque-UltraLight.otf') format('opentype');
}

@font-face {
    font-family: 'UxumL';
    src: url('./fonts/UxumGrotesque-Light.otf') format('opentype');
}

@font-face {
    font-family: 'Nord';
    src: url('./fonts/Nord.otf') format('opentype');
}