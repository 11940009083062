.detail-email {
    width: 100%;
    max-width: 100%;
    font-size: 1em;
}
.detail-email__input{
    appearance: none;
    outline: none;
    font-family: 'UxumR';
    border-width: 1px;
    border-radius: 3px;
    border-color: rgb(118, 118, 118);
    box-sizing: border-box;
    height: 1.75em;
    width: 100%;
    max-width: 100%;
    padding: 5px;
    margin-bottom: 1em;
}
.detail-email__text-area {
    appearance: none;
    outline: none;
    font-family: 'UxumR';
    border-radius: 3px;
    border-width: 1px;
    border-color: rgb(118, 118, 118);
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-height: 2em;
    height: 15vh;
    max-height: 18vh;
    padding: 5px;
    margin-bottom: 1em;
}
.detail-email__send {
    margin-top: -1%;
    appearance: none;
    outline: none;
    height: 2em;
    border-color: rgb(118, 118, 118);
    border-width: 1px;
    border-radius: 3px;
    background-color: rgb(255,255,255);
    font-family: 'UxumR';
    border-width: 1px;
    border-color: rgb(118, 118, 118);
    color: rgb(0,0,0);
    width: 100%;
    padding: 5px;
    cursor: pointer;
    transition: 0.5s ease-in-out;
}
.detail-email__send:hover {
    background-color: black;
    color: white;
    transition: 0.2s ease-in-out;
    cursor: pointer;
}
.detail-email--loading {
    font-size: 1em;
}
.detail-email--sent > p {
    font-size: 1em;
    margin-bottom: 0.5em;
}
.detail-email--sent > a {
    font-size: 1em;
}