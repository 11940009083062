.cms-login {
    display: flex;
    width: 50vw;
    margin: 2em;
}
.login__form {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.login__form > button {
    cursor: pointer;
    width: 10vw;
    margin-top: 0.5em;
    padding: 0.25em;
    border: 1px solid black;
    border-radius: 3px;
    transition: 0.25s ease-in-out;
}
.login__form > button:hover {
    background-color: rgb(200, 200, 200);
    color: black;
    transition: 0.25s ease-in-out;
}
.login__form > input {
    font-size: 1em;
}
.cms-login__error {
    color: red;
    font-size: 0.75em;
    margin-top: 0.5em;
}