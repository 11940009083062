.network {
    max-width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    overflow-x: auto;
    position: absolute;
    bottom: 0;
    width: 100vw;
    height: 60vh;
    /* z-index: 4; */
}
.network > p {
    /* writing-mode: vertical-lr;
	text-orientation: sideways; */
    color: white;
    margin: 0.5em;
    font-size: 3em;
}
.network__contact {
    display: flex;
    flex-direction: row;
    margin: 1em;
    padding: 1em ;
    background-color: rgba(255,255,255,0.9);
    color: black;
    border-radius: 4px;
    z-index: 4;
    filter: none;
    transition: padding 0.5s ease-in-out, filter 0.5s ease-in-out;
    
}
.network__contact:hover {
    padding: 1.5em;
    filter: drop-shadow(0px 5px 20px white);
    color: black;
    transition: padding 0.5s ease-in-out, filter 0.5s ease-in-out;
}
.network__contact__letter {
    font-size: 5em;
}

.network__contact__info {
    display: flex;
    flex-direction: column;
    margin-left: 1em;
    max-width: 200px;
    max-height: 1000px;
    /* transition: 1s ease-in-out; */
    transition: max-width 0.5s ease-in-out, max-height 0.75s ease-in-out;
    /* transition-delay: max-height 1s; */
}
.network__contact__info--collapsed {
    overflow: hidden;
    margin: 0;
    max-width: 0px;
    max-height: 0px;
    transition: max-width 0.25s ease-in-out, max-height 0.5s ease-in-out;
}
.network__contact__name {
    font-size: 1.2em;
    margin-bottom: 0.2em;
}
.network__contact__description {
    margin-bottom: 0.2em
}
.network__contact__website {
    margin-bottom: 0.2em
}
.network__contact__info__icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.network__contact__info__icons > a {
    margin-right: 0.5em;
}