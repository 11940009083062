.about-m {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.about-m__top {
    max-width: 50%;
    margin: auto;
}
.about-m__top > img {
    max-width: 100%;
    height: auto;
    display: block;
}
.about-m__bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 75%;
    margin: auto;
    margin-top: 10%;
    text-align: left;
}
.about-m__bottom > p {
    margin-bottom: 1em;
}
.about-m__social {
    display: flex;
    justify-content: space-between;
    padding: 0 5% 0 5%;
}
.about-m__social > a {
    margin-right: 3%;
}
.about-m__back {
    padding-top: 5%;
    padding-left: 5%;
}
/* .about__back_m > a {
    font-size: 2em;
} */