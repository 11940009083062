.explore-m {
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    background-size: cover;
    display: flex;
    flex-direction: column;
    /* background-color: rgb(0,0,0); */
}

.explore-m__home {
    /* position: absolute; */
    /* right: 0; */
    padding: 1em 0 0 1em;
}