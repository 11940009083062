html, body, #app, #app>div, #root {
    height: 100vh;
    /* this may cause errors for different screen size */
    /* overflow: hidden;  */
}
.container {
    position: relative;
}
/* from splash to portfolio transition --------------------------------------------- */
.stp-enter {
    position: absolute;
    width: 100vw;
    max-width: 100vw;
    transform: translate(-100vw, 0);
}
.stp-enter.stp-enter-active {
    transform: translate(0, 0);
    transition: 0.5s ease-in-out;
}
.stp-exit {
    position: absolute;
    max-width: 100vw;
    transform: translate(0, 0);
    opacity: 1;
}
.stp-exit.stp-exit-active {
    /* transform: translate(100vw, 0); */
    opacity: 0;
    transition: 0.5s ease-in-out;
}
/* from portfolio to splash --------------------------------------------- */
.pts-enter {
    position: absolute;
    max-width: 100vw;
    opacity: 0;
    /* transform: translate(100vw, 0); */
}
.pts-enter.pts-enter-active {
    /* transform: translate(0, 0); */
    opacity: 1;
    transition: 0.5s ease-in-out;
    transition-delay: 0.25s;
}
.pts-exit {
    position: absolute;
    width: 100vw;
    max-width: 100vw;
    transform: translate(0, 0);
}
.pts-exit.pts-exit-active {
    transform: translate(-100vw, 0);
    transition: 0.5s ease-in-out;
}
/* from splash to about transition --------------------------------------------- */
.sta-enter {
    position: absolute;
    max-width: 100vw;
    transform: translate(100vw, 0);
}
.sta-enter.sta-enter-active {
    transform: translate(0, 0);
    transition: 0.5s ease-in-out;
}
.sta-exit {
    position: absolute;
    max-width: 100vw;
    transform: translate(0, 0);
    opacity: 1;
}
.sta-exit.sta-exit-active {
    /* transform: translate(100vw, 0); */
    opacity: 0;
    transition: 0.5s ease-in-out;
}
/* from about to splash ---------------------------------------------  */
.ats-enter {
    position: absolute;
    max-width: 100vw;
    opacity: 0;
    /* transform: translate(100vw, 0); */
}
.ats-enter.ats-enter-active {
    /* transform: translate(0, 0); */
    opacity: 1;
    transition: 0.5s ease-in-out;
    transition-delay: 0.25s;
}
.ats-exit {
    position: absolute;
    max-width: 100vw;
    transform: translate(0, 0);
}
.ats-exit.ats-exit-active {
    transform: translate(100vw, 0);
    transition: 0.5s ease-in-out;
}
/* from portfolio to detail --------------------------------------------- */
.ptd-enter {
    position: absolute;
    max-width: 100vw;
    transform: translate(-100vw, 0);
}
.ptd-enter.ptd-enter-active {
    transform: translate(0, 0);
    transition: 0.5s ease-in-out;
}
.ptd-exit {
    position: absolute;
    width: 100vw;
    max-width: 100vw;
    transform: translate(0, 0);
}
.ptd-exit.ptd-exit-active {
    transform: translate(100vw, 0);
    transition: 0.5s ease-in-out;
}
/* from detail to portfolio --------------------------------------------- */
.dtp-enter {
    position: absolute;
    max-width: 100vw;
    transform: translate(100vw, 0);
}
.dtp-enter.dtp-enter-active {
    transform: translate(0, 0);
    transition: 0.5s ease-in-out;
}
.dtp-exit {
    position: absolute;
    width: 100vw;
    max-width: 100vw;
    transform: translate(0, 0);
}
.dtp-exit.dtp-exit-active {
    transform: translate(-100vw, 0);
    transition: 0.5s ease-in-out;
}
/* from splash to explore transition --------------------------------------------- */
.ste-enter {
    position: absolute;
    opacity: 0;
}
.ste-enter.ste-enter-active {
    opacity: 1;
    transition: 0.5s ease-in-out;
}
.ste-exit {
    position: absolute;
    opacity: 1;
}
.ste-exit.ste-exit-active {
    /* transform: translate(100vw, 0); */
    opacity: 0;
    transition: 0.5s ease-in-out;
}

.fade {
    height: 100vh;
    -webkit-animation: fadein 2s;
       -moz-animation: fadein 2s;
        -ms-animation: fadein 2s;
         -o-animation: fadein 2s; 
            animation: fadein 2s;
}
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
