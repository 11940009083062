.splash-m {
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    position: fixed;
    overflow: scroll;
    /* min-height: -webkit-fill-available; */
    /* background-color: rgba(255,255,255,0);
    transition: background-color 1s; */
}
.splash-m__title {
    position: absolute;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    top: 10%;
    transform: translateY(15%);
    padding-left: 10%;
    padding-right: 10%;
    font-size: 2em;
}
.splash-m__title > p {
    font-size: 1.5em;
    color: white;
}
.splash-m__about {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    width: 100%;
    top: 30%;
    transform: translateY(-15%);
    padding-left: 10%;
    padding-right: 10%;
}
.splash-m__portfolio {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
    width: 100%;
    top: 50%;
    transform: translateY(-15%);
    padding-left: 10%;
    padding-right: 10%;
}
.splash-m__explore {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    width: 100%;
    top: 70%;
    transform: translateY(-15%);
    padding-left: 10%;
    padding-right: 10%;
}
.splash-m__button {
    /* position: absolute; */
    font-size: 2em;
    background-color: rgba(0,0,0,0);
    transition: font-size 0.5s ease-in-out, color 0.2s;
}
.splash-m a {
    font-family: 'UxumR';
    color: rgba(255,255,255,1);
    text-decoration: none;
    padding: 0;
    transition: color 0.2s ease-in-out;
}
/* .splash a:hover {
    color: rgba(255,255,255,0.25);
    transition: color 0.2s ease-in-out;
} */
.splash-m a:visited {
    color: rgba(255,255,255,1);
    transition: color 0.2s ease-in-out;
}

.splash-m__p5 {
    /* top: 0; */
    /* transform: translate(50%, 50%); */
    position: absolute;
    z-index: -3;
}