.portfolio {
    /* border-style: solid;
    border-color: green; */
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 1% 2%;
    height: 100%;
    /* background: rgba(255,255,255,1);
    transition: background 1s; */
}
.portfolio > a:visited{
    color: rgba(0,0,0,1);;
}

.portfolio__header {
    font-family: 'UxumR';
    /* border-style: solid; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 5%;
}
a {
    text-decoration: none;
    color: rgba(0,0,0,1);
    transition: color 0.5s;
}
.portfolio__header__adehle a {
    color: rgba(0,0,0,1);
    transition: color 0.5s;
}
.portfolio__header__adehle a:visited {
    color: rgba(0,0,0,1);
}
a:hover {
    color: rgba(0,0,0,0.5);
    transition: color 0.5s;
}
.portfolio__header__adehle {
    color: rgba(0,0,0,1);
    font-size: 1.4em;
}
.portfolio__content {
    /* border-style: solid; */
    max-width: 98%;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
}
.portfolio__list {
    /* border-style: solid; */
    /* display: flex; */
    /* min-width: 25vw; */
    width: 25%;
    max-width: 25%;
    min-width: 25%;
    /* width: 25vw; */
    flex-basis: 100%;
    /* position: absolute; */
}

.portfolio__header__button {
    font-size: 1em;
    padding-right: 5%;
}
.portfolio__image {
    display: flex;
    justify-content: center;
    width: 75%;
}
/* button {
    background-color: transparent;
    max-width: 100%;
    padding: 0;
} */