.explore {
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    background-size: cover;
    /* background-color: rgb(0,0,0); */
}
.explore__home {
    position: absolute;
    right: 0;
    padding: 2em;
}