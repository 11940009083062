.edit-artwork {
    border-style: solid;
    border-width: 1px;
    margin-bottom: 2em;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2.5%;
    padding-bottom: 2.5%;
    font-family: Arial;
}
.edit-artwork__artwork {
    /* border-style: solid;
    border-color: green;
    border-width: 1px; */
    display: flex;
    flex-direction: column;
    padding-bottom: 1em;
    padding-top: 1em;
    border-top: 1px solid black;
    
}
.edit-artwork__top {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.edit-artwork__index {
    width: 10%;
    margin-right: 10px;
}
.edit-artwork--collapsed {
    display: none;
}
.edit-artwork__right {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}