.edit-profile {
    border-style: solid;
    border-width: 1px;
    margin: 1% 5% 5%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2.5%;
    padding-bottom: 2.5%;
}
.edit-profile__image {
    max-width: 25%;
}
.edit-profile__image > img {
    max-width: 100%;
    height: auto;
    display: block;
}
.submit {
    max-width: 25%;
}