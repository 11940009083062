.upload {
    border-style: solid;
    border-width: 1px;
    margin: 1% 5% 5%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2.5%;
    padding-bottom: 2.5%;
    /* background-color: gray; */
}
label {
    width: 100%;
    display: inline-block;
    padding-top: 10px;
    /* background-color: black; */
}
select {
    width: 70%;
    font-size: 1em;
}
p {
    padding: 0px;
    font-size: 1em;
}
input {
    width: 70%;
    font-size: 1em;
    font-family: Arial;
}
.submit {
    padding-top: 10px;
}
.upload__index {
    /* padding: 100px; */
    display: flex;
    border-style: solid;
    border-width: 1px;
}
.upload__right {
    display: flex;
    border-style: solid;
    border-width: 1px;
}