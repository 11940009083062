.collection-list-m {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    width: 100%;
}
body {
    min-height: 0px;
}
.collection-list-m__collection > button {
    background-color: transparent;
    max-width: 100%;
    padding: 5% 0 0 0;
}
.collection-list-m__collection {
    max-height: 50px;
    opacity: 1;
    transition: 0.5s ease-in-out;
    transition-delay: 0.5s;
}
.collection-list-m__collection--collapsed {
    pointer-events: none;
    max-height: 0px;
    opacity: 0;
    transition: 0.5s ease-in-out;
}