.portfolio-m {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    /* background-color: white;  */
    /* background-color: rgba(255,255,255,1);
    transition: background-color 1s; */
}
.portfolio-m > a:visited{
    color: rgba(0,0,0,1);;
}

.portfolio-m__header {
    width: 90%;
    font-family: 'UxumR';
    /* border-style: solid; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* height: 5%; */
    margin: 5%;
    position: fixed;
    background-color: rgba(255,255,255,0.7);
}
a {
    text-decoration: none;
    color: rgba(0,0,0,1);
    transition: color 0.5s;
}
.portfolio-m__header__adehle a {
    color: rgba(0,0,0,1);
    transition: color 0.5s;
}
.portfolio-m__header__adehle a:visited {
    color: rgba(0,0,0,1);
}
a:hover {
    color: rgba(0,0,0,0.5);
    transition: color 0.5s;
}
.portfolio-m__header__adehle {
    color: rgba(0,0,0,1);
    font-size: 1.4em;
}
.portfolio-m__content {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    margin: 12% 5% 0 5%;
    font-size: 1.2em;
    /* flex-basis: 100%; */
}
