.splash {
    position: relative;
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
}
.splash__top {
    position: absolute;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    top: 15%;
    transform: translateY(15%);
    padding-left: 10%;
    padding-right: 10%;
    font-size: 2em;
}
.splash__bottom > p {
    font-size: 3em;
    color: white;
}
.splash__bottom {
    align-items: flex-end;
    position: absolute;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    top: 85%;
    transform: translateY(-15%);
    padding-left: 10%;
    padding-right: 10%;
    
}
.splash__button {
    /* position: absolute; */
    font-size: 2em;
    background-color: rgba(0,0,0,0);
    transition: font-size 0.5s ease-in-out, color 0.2s;
}
.splash a {
    font-family: 'UxumR';
    color: rgba(255,255,255,1);
    text-decoration: none;
    padding: 0;
    transition: color 0.2s ease-in-out;
}
.splash a:hover {
    color: rgba(255,255,255,0.25);
    transition: color 0.2s ease-in-out;
}
.splash a:visited {
    color: rgba(255,255,255,1);
    transition: color 0.2s ease-in-out;
}
/* .splash__button:hover {
    font-size: 2.2em;
    transition: font-size 0.5s ease-in-out
} */
.splash__p5 {
    /* top: 0; */
    /* transform: translate(50%, 50%); */
    position: absolute;
    /* z-index: -3; */
}