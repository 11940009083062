.detail {
    margin-top: 1em;
    border-top: 1.5px solid rgb(118, 118, 118);
    padding-top: 1em;
    display: flex;
    flex-direction: column;
    max-height: 500px;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
}
.detail-b > p {
    margin-bottom: 1em;
}
.detail--collapsed {
    border: 0;
    max-height: 0px;
    /* padding: 0; */
    /* margin: 0; */
    transition: max-height 0.5s ease-in-out;
}