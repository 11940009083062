.artwork {
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 1;
    -webkit-transition: opacity 1.2s;
    transition: opacity 1.2s;
    /* max-width: 100%; */
}
.artwork--hidden {
    opacity: 0.01;
    -webkit-transition: opacity 1s;
    transition: opacity 1s;
}
.artwork__next {
    text-align: left;
    /* position: absolute; */
}
.artwork > img {
    /* width: 40vw; */
    max-width: 70vw;
    max-height: 90vh;
    /* height: 80vh; */
    /* object-fit: contain; */
}
p {
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding-top: 10px;
}

/* .container {
    position: relative;
} */
.crossfade-leave {
    opacity: 1;
}
.crossfade-leave.crossfade-leave-active {
    opacity: 0;
    transition: opacity 1s ease-in;
}
.crossfade-enter {
    opacity: 0;
}
.crossfade-enter.crossfade-enter-active {
    opacity: 1;
    transition: opacity 1s ease-in;
}
