.edit-collection {
    border-style: solid;
    border-width: 1px;
    margin: 1% 5% 5%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2.5%;
    padding-bottom: 2.5%;
    font-family: Arial;
}
.edit-collection__collection {
    display: flex;
    flex-direction: row;
}

.edit-collection__collection__index {
    width: 10%;
    margin-right: 10px;
    /* border-style: solid;
    border-width: 1px; */
    /* justify-content: center; */
    /* text-align: center; */
}

.edit-collection__collection__right {
    display: flex;
    flex-direction: column;
    width: 90%;
}
.edit-collection__collection__right > button {
    padding: 0;
    padding-bottom: 1em;
}