.edit-network {
    border-style: solid;
    border-width: 1px;
    margin: 1% 5% 5%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2.5%;
    padding-bottom: 2.5%;
}
.edit-network__contact {
    padding-bottom: 1em;
    margin-bottom: 1em;
    border-bottom: 1px solid black;
}