.edit-network-new-contact {
    border-style: solid;
    border-width: 1px;
    margin: 1% 5% 5%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2.5%;
    padding-bottom: 2.5%;
}

textarea {
    width: 70%;
    height: 100px;
    font-size: 1em;
    font-family: Arial;
}